import { piexif } from 'piexifjs'
import { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { addImage, reColorCorrectAllImages, reColorCorrectImage } from './actions'
import { addColorCorrectedFilenameSuffix } from './service'
import {
  clearImages,
  setImageCorrectionOverride,
  setImageCorrectionOverrides,
} from './slice'

export const AquaTintColorCorrectionFilePicker = () => {
  const dispatch = useDispatch()

  const handleImageUpload = async (event) => {
    dispatch(clearImages())

    const files = Array.from(event.target.files)
    const readers = files.map((file) => {
      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.onload = (e) => {
          let exif = null
          try {
            exif = piexif.load(e.target.result)
          } catch (error) {
            console.error(error)
          }
          resolve({
            originalImageSrc: e.target.result,
            fileName: file.name,
            exif,
            id: Math.random(),
          })
        }
        reader.readAsDataURL(file)
      })
    })

    const loadedImages = await Promise.all(readers)

    loadedImages.map((image) => dispatch(addImage(image)))
  }

  return (
    <div className="label-input-container">
      <label htmlFor="imageUpload">Image(s) to correct:</label>
      <input
        id="imageUpload"
        type="file"
        multiple
        onChange={handleImageUpload}
        accept="image/*"
      />
    </div>
  )
}

export const AquaTintImagePair = ({ beforeImage, afterImageDataURL }) => {
  const dispatch = useDispatch()
  const globalRedBlueAdjustment = useSelector(
    (state) => state.colorCorrection.defaultRedBlueAdjustment,
  )
  const imgRef = useRef(null)

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100%',
        margin: '0.5em',
        gap: '0.5em',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          gap: '0.2em',
          maxWidth: '100%',
          overflow: 'hidden',
        }}
      >
        <div style={{ flex: 1 }}>
          <img
            ref={imgRef}
            src={beforeImage.originalImageSrc}
            alt="Before color correction"
            style={{ width: '100%', height: 'auto' }}
          />
        </div>
        <div style={{ flex: 1 }}>
          <a
            href={afterImageDataURL}
            download={addColorCorrectedFilenameSuffix(beforeImage.fileName)}
          >
            <img
              src={afterImageDataURL}
              title="Click to download"
              alt="After color correction"
              style={{
                width: '100%',
                height: 'auto',
              }}
            />
          </a>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          gap: '0.2em',
          maxWidth: '100%',
        }}
      >
        <input
          type="range"
          style={{ flex: 1 }}
          min={0.05}
          max={2.5}
          step={0.05}
          value={
            beforeImage.correctionOverride?.redBlueAdjustment || globalRedBlueAdjustment
          }
          onChange={(e) => {
            dispatch(
              setImageCorrectionOverride({
                imageId: beforeImage.id,
                correctionOverride: {
                  redBlueAdjustment: parseFloat(e.target.value),
                },
              }),
            )
            dispatch(reColorCorrectImage(beforeImage.id))
          }}
          title="Adjustment parameter"
        />
        <input
          type="number"
          title="Adjustment parameter"
          style={{ width: '3.5em' }}
          min={0.05}
          max={2.5}
          step={0.05}
          value={(
            beforeImage.correctionOverride?.redBlueAdjustment || globalRedBlueAdjustment
          ).toFixed(2)}
          onChange={(e) => {
            dispatch(
              setImageCorrectionOverride({
                imageId: beforeImage.id,
                correctionOverride: {
                  redBlueAdjustment: parseFloat(e.target.value),
                },
              }),
            )
            dispatch(reColorCorrectImage(beforeImage.id))
          }}
        />
        <button
          type="button"
          title="Use this adjustment for all pictures"
          onClick={() => {
            dispatch(
              setImageCorrectionOverrides({
                redBlueAdjustment:
                  beforeImage.correctionOverride?.redBlueAdjustment ||
                  globalRedBlueAdjustment,
              }),
            )
            dispatch(reColorCorrectAllImages())
          }}
        >
          🔁
        </button>
      </div>
    </div>
  )
}
