import { createSlice } from '@reduxjs/toolkit'

import { addImage, reColorCorrectAllImages, reColorCorrectImage } from './actions'

const initialState = {
  images: [],
  defaultThresholdRatio: 1300,
  defaultRedBlueAdjustment: 1,
}

export const colorCorrectionSlice = createSlice({
  name: 'colorCorrection',
  initialState,
  reducers: {
    setImageCorrectionOverride: (state, action) => {
      const image = state.images.find((image) => image.id === action.payload.imageId)
      image.correctionOverride = action.payload.correctionOverride
    },
    setImageCorrectionOverrides: (state, action) => {
      state.images.forEach((image) => {
        image.correctionOverride = action.payload
      })
    },
    removeImage: (state, action) => {
      state.images = state.images.filter((image) => image.id !== action.payload)
    },
    clearImages: (state) => {
      state.images = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addImage.fulfilled, (state, action) => {
        state.images.push(action.payload)
      })
      .addCase(reColorCorrectImage.fulfilled, (state, action) => {
        const image = state.images.find((image) => image.id === action.payload.imageId)
        image.colorCorrectedImageDataURL = action.payload.colorCorrectedImageDataURL
      })
      .addCase(reColorCorrectAllImages.fulfilled, (state, action) => {
        state.images = action.payload
      })
  },
})

export const { setImageCorrectionOverride, setImageCorrectionOverrides, clearImages } =
  colorCorrectionSlice.actions

export default colorCorrectionSlice.reducer
