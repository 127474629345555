import { piexif } from 'piexifjs'

// GPL-3 licensed, grabbing from <script>-element in index.html
const getColorFilterMatrix = window.getColorFilterMatrix

export function drawColorCorrectedImage(
  originalImageSrc,
  exif,
  thresholdRatio,
  redBlueAdjustment,
) {
  return new Promise((resolve) => {
    const canvas = document.getElementById('color-correction-canvas')
    const ctx = canvas.getContext('2d')
    const image = new Image()

    image.src = originalImageSrc
    image.onload = () => {
      canvas.width = image.width
      canvas.height = image.height

      // Draw original image to get its ImageData
      ctx.drawImage(image, 0, 0)
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
      const data = imageData.data

      const filter = getColorFilterMatrix(data, image.width, image.height, {
        thresholdRatio,
        blueMagicValue: redBlueAdjustment,
      })

      for (var i = 0; i < data.length; i += 4) {
        data[i] = Math.min(
          255,
          Math.max(
            0,
            data[i] * filter[0] +
              data[i + 1] * filter[1] +
              data[i + 2] * filter[2] +
              filter[4] * 255,
          ),
        ) // Red
        data[i + 1] = Math.min(
          255,
          Math.max(0, data[i + 1] * filter[6] + filter[9] * 255),
        ) // Green
        data[i + 2] = Math.min(
          255,
          Math.max(0, data[i + 2] * filter[12] + filter[14] * 255),
        ) // Blue
      }

      // Draw corrected ImageData
      ctx.putImageData(imageData, 0, 0)

      const imageJpegDataURL = canvas.toDataURL('image/jpeg')
      const exifData = piexif.dump(exif)
      const imageJpegDataURLWithExif = piexif.insert(exifData, imageJpegDataURL)

      resolve(imageJpegDataURLWithExif)
    }
  })
}

export function addColorCorrectedFilenameSuffix(filename) {
  if (!filename.includes('.')) {
    console.error('Invalid file name. No extension found.')
    return filename
  }

  const parts = filename.split('.')
  const extension = parts.pop()
  const baseName = parts.join('.')

  return `${baseName}_color_corrected_AquaTint.${extension}`
}
