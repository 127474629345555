import { useSelector } from 'react-redux'

import './App.css'
import {
  AquaTintColorCorrectionFilePicker,
  AquaTintImagePair,
} from './features/color-correction/components'

function App() {
  const images = useSelector((state) => state.colorCorrection.images)

  return (
    <div>
      <div className="header">
        <h1>AquaTint</h1>
        <h2>Color-correct pictures taken under water</h2>
      </div>
      <div className="app-description">
        <p>
          Drag the slider to modify the color correction parameter. Press the corrected
          image to download it. All processing is done locally, your pictures never leave
          your device.
        </p>
      </div>
      <div className="controls">
        <AquaTintColorCorrectionFilePicker />
      </div>
      <div style={{ marginTop: '8px' }}>
        {images.map((image) => (
          <div
            key={image.id}
            style={{
              margin: '16px 8px',
              border: '1px solid #aaa',
              borderRadius: '0.5em',
            }}
          >
            <AquaTintImagePair
              beforeImage={image}
              afterImageDataURL={image.colorCorrectedImageDataURL}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default App
