import { createAsyncThunk } from '@reduxjs/toolkit'

import { drawColorCorrectedImage } from './service'

const colorCorrectImage = async (image, thresholdRatio, redBlueAdjustment) => {
  const { originalImageSrc, exif } = image
  const colorCorrectedImageDataURL = await drawColorCorrectedImage(
    originalImageSrc,
    exif,
    thresholdRatio,
    redBlueAdjustment,
  )

  return colorCorrectedImageDataURL
}

export const addImage = createAsyncThunk(
  'colorCorrection/addImage',
  async (image, thunkAPI) => {
    const { defaultThresholdRatio, defaultRedBlueAdjustment } =
      thunkAPI.getState().colorCorrection

    const colorCorrectedImageDataURL = await colorCorrectImage(
      image,
      defaultThresholdRatio,
      defaultRedBlueAdjustment,
    )

    return { colorCorrectedImageDataURL, ...image }
  },
)

export const reColorCorrectImage = createAsyncThunk(
  'colorCorrection/reColorCorrectImage',
  async (imageId, thunkAPI) => {
    const { images, defaultRedBlueAdjustment, defaultThresholdRatio } =
      thunkAPI.getState().colorCorrection

    const image = images.find((image) => image.id === imageId)

    return {
      imageId,
      colorCorrectedImageDataURL: await colorCorrectImage(
        image,
        defaultThresholdRatio,
        image.correctionOverride?.redBlueAdjustment || defaultRedBlueAdjustment,
      ),
    }
  },
)

export const reColorCorrectAllImages = createAsyncThunk(
  'colorCorrection/reColorCorrectAllImages',
  async (_, thunkAPI) => {
    const { images, defaultRedBlueAdjustment, defaultThresholdRatio } =
      thunkAPI.getState().colorCorrection

    return await Promise.all(
      images.map(async (image) => ({
        ...image,
        colorCorrectedImageDataURL: await colorCorrectImage(
          image,
          defaultThresholdRatio,
          image.correctionOverride?.redBlueAdjustment || defaultRedBlueAdjustment,
        ),
      })),
    )
  },
)
